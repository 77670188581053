import { useTranslation } from 'react-i18next'
import s from './style.module.css'
import benefits from '../../../Images/digibot-benefits.png'

export default function Benefits() {
  const { t, i18n } = useTranslation()

  const isArabic = i18n.resolvedLanguage === 'ar' ?? false
  const tn = t('digibot', { returnObjects: true }).benefits

  return (
    <div className={s.sectionContainer} dir={isArabic && 'rtl'}>
      <section className={s.section}>
        <div className={s.textContainer}>
          <h1 className={`${s.text} ${s.heading}`}>
            {tn.heading.main}{' '}
            <span className={s.emphasis}>{tn.heading.emphasis}</span>
          </h1>

          <ul>
            {tn.items.map((item, index) => (
              <li className={s.item} key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>

        <img src={benefits} alt={tn.benefits} className={s.hero} />
      </section>
    </div>
  )
}
