import { useTranslation } from 'react-i18next'
import s from './style.module.css' 

export default function ContactForm() {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.resolvedLanguage === 'ar' ?? false
  const tn = t('digibot', { returnObjects: true }).contact

  return (
    <section className={s.section} dir={isArabic ? "rtl" : "ltr"}>
      <h1 className={`${s.heading} ${s.text}`}>
        {tn.heading.main}
      </h1>

      <form className={s.form}>
        <input className={s.input} type="email" placeholder={tn.form.email.placeholder} /> 
        <textarea className={`${s.input} ${s.textarea}`} placeholder={tn.form.message.placeholder}></textarea>
        <button className={s.submitBtn} type="submit">{tn.form.submitButton.text}</button>
      </form>
    </section> 
  )
}
