import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./App.css";
import loadable from "@loadable/component";

import NewNavBar from "./Component/NavBar/NewNavBar.js";
import Home from "./Component/Home/Home.js";
import About from "./Component/About/About.js";
import WebMail from "./Component/WebMail.js";
import PageNotFound from "./Component/NotFound/PageNotFound.js";
import Footer from "./Component/Footer/Footer";
import ContactUsPage from "./Component/ContactUsPage/ContactUsPage.js";
import Digibot from "./Component/Digibot/index.jsx";

const Jobs = loadable(() => import("./Component/Jobs/Jobs"));
const ApplyForAJob = loadable(() => import("./Component/Jobs/ApplyForAJob"));
const Services = loadable(() => import("./Component/Se_rvices/Services.js"));
const AppsDigi = loadable(() => import("./Component/Apps/AppsDigi.js"));

const Blogs = loadable(() => import("./Component/Blogs/Blogs.js"));
const WebDevelopment = loadable(() =>
  import("./Component/Se_rvices/WebDevelopment.js")
);
const MobileDevelopment = loadable(() =>
  import("./Component/Se_rvices/MobileDevelopment.js")
);
const DigitalMarkting = loadable(() =>
  import("./Component/Se_rvices/DigitalMarkting.js")
);

const CustomPortfolio = loadable(() =>
  import("./Component/CustomPortfolio/CustomPortfolio")
);
const GoToTop = loadable(() => import("./Component/GoToTop"));
const OurProjects = loadable(() => import("./Component/Apps/OurProjects"));
const Invest = loadable(() => import("./Component/Invest/Invest"));

const MobileApps = loadable(() => import("./Component/MobileApps"));
const MobileAppse = loadable(() => import("./Component/MobileAppse"));
const Digisol = loadable(() => import("./Component/digisol"));
const Digisol2 = loadable(() => import("./Component/Digisol2"));

function App() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    setLanguage(i18n.resolvedLanguage ?? "en")
  }, [i18n.resolvedLanguage])

  let baseUrl = `https://dashboard.company.digisolapps.com/api/${language}/digisol`;

  let sliderApi = `${baseUrl}/slider`;

  let ourServicesHome = `${baseUrl}/service`;

  let aboutUsParagraph = `${baseUrl}/about_us/paragraph`;

  let footerData = `${baseUrl}/footers`;

  let jobsPosts = `${baseUrl}/jobs`;

  let jobsName_api = `${baseUrl}/jobs/name`;

  let jobsExperince_api = `${baseUrl}/jobs/experience`;

  let aboutUS_api = `${baseUrl}/about_us`;

  let url = `${baseUrl}/home`;

  let url3 = `${baseUrl}/Apps`;

  let url4 = `${baseUrl}/Services`;

  let invest_API = `${baseUrl}/InvestmentProject`;
  let testimonailas = `${baseUrl}/testimonials`;

  let url6 =
    "https://dashboard.company.digisolapps.com/api/en/digisol/settings";

  let url7 = `${baseUrl}/blog`;

  let url8 = `${baseUrl}/blog/lastThreeBlogs`;
  let metaDataUrl = `${baseUrl}/seo`;

  return (
    <BrowserRouter>
      <div className="App">
        <NewNavBar
          onLanguageChange={(lang) => setLanguage(lang)}
          dlanguage={language}
        />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                url={url}
                url2={url8}
                sliderApi={sliderApi}
                ourServicesHome={ourServicesHome}
                metaDataUrl={metaDataUrl}
                testimonailas={testimonailas}
              />
            }
          />
          <Route
            exact
            path="/mobile-development-applications-in-jordan-and-saudi-arabia"
            element={
              <About
                aboutUsParagraph={aboutUsParagraph}
                metaDataUrl={metaDataUrl}
              />
            }
          />
          <Route
            exact
            path="/websites-development-applications-in-jordan-and-saudi-arabia-middle-east"
            element={
              <Services
                aboutUS_api={aboutUS_api}
                url4={ourServicesHome}
                metaDataUrl={metaDataUrl}
              />
            }
          />
          <Route 
            exact
            path="/digibot"
            element={<Digibot />}
          /> 
          <Route
            exact
            path="/applications-ios-android-huawei-apps-app-store"
            element={<AppsDigi url={url3} />}
          />
          <Route
            exact
            path="/applications-ios-android-huawei-apps-app-store-jordan"
            element={<OurProjects url={url3} metaDataUrl={metaDataUrl} />}
          />
          <Route
            exact
            path="/applications-ios-android-huawei-apps-app-store-jordan/:id"
            element={<CustomPortfolio url={url3} />}
          />
          <Route
            exact
            path="/blogs-webBlogs-phoneBlogs-appBlogs-articles-saudi-arabia-jordan"
            element={<Blogs url={url7} metaDataUrl={metaDataUrl} />}
          />
          <Route
            exact
            path="/invest-in-apps-mobile-applications-online-investments"
            element={
              <Invest invest_API={invest_API} metaDataUrl={metaDataUrl} />
            }
          />
          <Route
            exact
            path="/MobileDevelopment"
            element={<MobileDevelopment url={url4} />}
          />
          <Route
            exact
            path="/WebDevelopment"
            element={<WebDevelopment url={url4} />}
          />
          <Route
            exact
            path="/DigitalMarkting"
            element={<DigitalMarkting url={url4} />}
          />
          <Route exact path="/webmail/mail" element={<WebMail />} />
          <Route exact path="/webmail" element={<WebMail />} />
          <Route
            exact
            path="/contact-IT-company-Jordan-mobile-applications-development"
            element={<ContactUsPage url={url6} metaDataUrl={metaDataUrl} />}
          />
          <Route
            exact
            path="/javascript-react-js-python-IT-jobs-in-jordan-php-laravel"
            element={
              <Jobs
                jobsName_api={jobsName_api}
                jobsExperince_api={jobsExperince_api}
                jobsPosts={jobsPosts}
                metaDataUrl={metaDataUrl}
              />
            }
          />
          <Route exact path="/Jobs/:id" element={<ApplyForAJob />} />
          <Route
            exact
            path="/برمجة-تطبيقات-في-الاردن"
            element={<MobileApps />}
          />
          <Route exact path="/برمجة-تطبيقات" element={<MobileApps />} />
          <Route exact path="/شركة برمجة تطبيقات" element={<MobileApps />} />
          <Route exact path="/شركة برمجة في الاردن" element={<MobileAppse />} />
          <Route exact path="/شركة برمجة ديجيسول" element={<MobileAppse />} />
          <Route
            exact
            path="/برمجة تطبيقات الهاتف في الاردن"
            element={<Digisol />}
          />
          <Route
            exact
            path="/برمجة تطبيقات الهاتف في السعودية"
            element={<Digisol2 />}
          />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
        <Footer
          url={footerData}
          language={language}
          onLanguageChange={(lang) => setLanguage(lang)}
        />
        <GoToTop />
      </div>
    </BrowserRouter>
  );
}

export default React.memo(App);
