import { useTranslation } from 'react-i18next'
import s from './style.module.css'
import ProcessCard from './components/card'

export default function Process() {
  const { t, i18n } = useTranslation()
  const tn = t('digibot', { returnObjects: true }).process
  const isArabic = i18n.resolvedLanguage === 'ar' ?? false

  return (
    <section className={s.section} dir={isArabic && 'rtl'}>
      <h1 className={`${s.heading} ${s.text}`}>
        <span className={`${s.emphasis} ${s.text}`}>{tn.heading.emphasis}</span>{' '}
        {tn.heading.main}
      </h1>

      <div className={s.cardsContainer}>
        {tn.steps.map((item, index) => (
          <ProcessCard item={item} key={index} />
        ))}
      </div>
    </section>
  )
}
