import React from "react";
import { createRoot } from "react-dom/client";
import './fonts/beINNormal.ttf';
import './i18n'
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
 
reportWebVitals();
