import { useCallback, useState, useEffect } from "react"

export const useIsScrollingDown = () => {
  const [scrollOffset, setScrollOffset] = useState(0)
  const [isScrollingDown, setIsScrollingDown] = useState(true)

  const handleScroll = useCallback(() => {
    const y = window.scrollY
    setIsScrollingDown(y > scrollOffset)
    setScrollOffset(y)
  }, [scrollOffset])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return { isScrollingDown }
}
