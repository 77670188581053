import React, { useMemo, useState } from "react";
import loadable from '@loadable/component'

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const TimeLine = loadable(() => import("./TimeLine.js"));
const GoToTop = loadable(() => import("../GoToTop.js"));
const About = ({ url, aboutUsParagraph, metaDataUrl }) => {
  const { i18n } = useTranslation();
  const [metaData, setMetaData] = useState([]);
  const { t } = useTranslation();

  const dir = {
    direction: `${i18n.dir()}`,
  };

  const getMetaData = async () => {
    const response = await fetch(metaDataUrl);
    const data = await response.json();
    setMetaData(data);
  };
  console.log(metaData)

  useMemo(() => {
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaDataUrl]);

  return (
    <div
      id="About"
      className={`${dir.direction === "ltr" ? "leftToRigh" : "rightToLeft"}`}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaData[0]?.title}</title>
        <meta name="keywords" content={metaData[0]?.keywords} />
        <meta name="description" content={metaData[0]?.description} />
      </Helmet>

      <TimeLine url={url} aboutUsParagraph={aboutUsParagraph} />
      <h1 className="headingWin">
        {t("mobiledevelopmentapplicationsinjordanandsaudiarabia")}
      </h1>
      <h1 className="headingWin">
        {t("restaurantdevelopmentapplicationsinjordanandsaudiarabia")}
      </h1>
      <h1 className="headingWin">
        {t("restaurantappsapplicationsinjordanandsaudiarabia")}
      </h1>
      <h1 className="headingWin">
        {t("deliveryappsapplicationsinjordanandsaudiarabia")}
      </h1>
      <h1 className="headingWin">
        {t("webdevelopmentapplicationsinjordanandsaudiarabia")}
      </h1>
      <GoToTop />
    </div>
  );
};

export default React.memo(About);
