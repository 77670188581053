export const TRANSLATIONS_AR = {
  home: 'الرئيسية',
  about: 'عنا',
  careers: 'الوظائف',
  ourproducts: 'منتجاتنا',
  contact: 'تواصل معنا',
  whoweare: 'من نحن',
  our_products: 'منتجاتنا',
  our_customers: 'عملائنا',
  testimonials: 'أراء العملاء',
  news: 'اخبار',
  wanttoknowmorw: 'لمعرفة المزيد ارسال رسالة ',
  submit: 'ارسال',
  name: 'الاسم',
  email: 'الايميل',
  subject: 'الموضوع',
  message: 'الرسالة',
  weeklyBlogs: 'مقالات اسبوعية',
  seeall: 'اعرض الكل',
  readmore: 'تابع القراءة',
  vision: 'رؤيتنا',
  mission: 'مهمتنا',
  values: 'قيمنا',
  iNGREDIENTS: 'مكونات',
  mailus: 'راسلنا',
  findus: 'تجدنا',
  ourApplication: 'تطبيقنا',
  ourServices: 'خدمتنا',
  ourProjects: 'مشاريعنا',
  browse: 'اطلع على مشاريعنا',
  knowMore: 'اعرف أكثر',
  mobileAppDevelopment: 'تطوير تطبيقات الهاتف',
  webAppDevelopment: 'تطوير تطبيقات الويب',
  digitalMarkting: 'التسويق الرقمي',
  whatWeDo: 'ماذا نفعل',
  discoverOurApplications: 'أكتشف تطبيقتنا',
  ourApps: 'مشاريعنا',
  getInTouch: '!تواصل معنا بسهولة',
  getInTouchFooter: 'تواصل معنا',
  getDescription:
    'من السهل جدًا التواصل معنا. فقط استخدم نموذج الاتصال  أو قم بزيارة لشرب كوب قهوة في شركتنا',
  address: 'العنوان',
  street: 'شارع مكة',
  amman: 'عمان',
  phone: 'هاتف',
  Sendusamessage: 'أرسل لنا رسالة',
  query: 'في حال كان لديك اي نوع من الاستفسارات ارسل لنا رسالة',
  blogs: 'المدونة',
  blogsLatest: 'أخر مدوناتنتا',
  ourProcess: 'أليتنا',
  OurAppsCategories: 'تصنيفات تطبيقتنا',
  DigitalMarketingServicesWeOffer: 'خدمات التسويق الألكتروني التي نوفرها',
  SearchEngineOptimizationService: 'خدمات تحسين نتائج محركات البحث',

  seoParagraph:
    'يعد تحسين محرك البحث أو تحسين محركات البحث (SEO) جزءًا لا يتجزأ من إنشاء شركة لها وجود عبر الإنترنت. جميع عملائك تقريبًا متصلون بالإنترنت وتحتاج إلى التأكد من أنهم قادرون على العثور عليك. نحن هنا في Digisol نقدم ذلك بالضبط. لدينا فريق SEO رائع يتكون من خبراء تحسين محركات البحث مع سنوات من الخبرة. سيجلس فريقنا متعدد الاستخدامات معك ويعمل على وضع خطة تحسين محركات بحث مخصصة ومناسبة خصيصًا لعملك. مع حلول Digisol SEO ، ستحصل على:',
  pOne: 'مدير SEO مخصص',
  pTwo: 'متعمق السوق وتحليل المنافسين',
  pThree: 'التدقيق الفني للموقع',
  pFour: 'تحسين مواقع الويب',
  pFive: 'إعادة تصميم الصفحة المقصودة',
  pSix: 'انشاء محتوى',
  pSeven: 'إدارة الإعلانات',
  pEight: 'تقارير شفافة مع مقاييس سهلة الفهم',
  whatsApp: 'واتس أب',
  yourinquiryabout: 'استفسارك حول',
  ILiketodiscussyouroffer: 'أحب مناقشة العرض',
  callMeBack: 'أتصلو في لاحقاً',
  qInq: 'لدي عدة استفسارات',
  Others: 'أخرى',
  userFr: 'تصميمات مناسبة للمستخدم',
  projects: 'تطبيقاتنا',
  sync: 'تطبيقات مناسبة لهاتفك و أسلوب حياتك',
  Testimonials: 'أراء',
  ourClients: 'Our Clients',
  lang: 'اللغة',
  store: 'الصفحات',
  apply: 'قدم الأن',
  services: 'خدماتنا',
  clients: 'عملائنا',
  team: 'فريقنا',
  tech: 'تكنولوجيا',
  invest: 'استثمر',
  formoreinformation: 'لمزيد من التفاصيل تواصل معنا',
  send: 'ارسال',
  selectproject: 'اختر مشروع',
  thankyouWewillcontactyousoon: 'شكرا لك سنقوم بالتواصل معك قريبا',
  Findoutwhatourmostsuccessfulclientssayaboutus:
    'اكتشف ما يقوله عملاؤنا الأكثر نجاحًا عنا',
  Itsalwaysthebestsourcetohearfromotherswhohavealreadyworkedwithus:
    'إنه دائمًا أفضل مصدر للاستماع إليه من الآخرين الذين عملوا معنا بالفعل',
  RECENTHIGHLIGHTS: 'أحدث المقالات',
  Learnmoreaboutmarktinginourfeaturedblogentries:
    'تعرف على المزيد حول التسويق في مدونتنا المميزة',
  Deliveramazingdigitalexperiences: 'تقديم تجارب رقمية مذهلة',
  Wevehelpedthousandsofcompaniesjustlikeyourschangetheirbusinesses:
    'لقد ساعدنا آلاف الشركات ، مثل شركتك تمامًا ، على تغيير أعمالها',
  ApplicationSent: 'شكرا لك✨ تم إرسال طلبك',
  vacancies: 'شواغر',
  seoAltHome: 'تطوير تطبيقات الهاتف في الاردن',
  mobiledevelopmentapplicationsinjordanandsaudiarabia:
    'تطوير تطبيقات  الهواتف المحمولة في الأردن والمملكة العربية السعودية',
  restaurantdevelopmentapplicationsinjordanandsaudiarabia:
    'تطوير تطبيقات المطاعم في الأردن والمملكة العربية السعودية',
  restaurantappsapplicationsinjordanandsaudiarabia:
    'تطبيقات المطاعم في الاردن والسعودية',
  deliveryappsapplicationsinjordanandsaudiarabia:
    'تطبيقات توصيل الطلبات في الاردن والسعودية',
  webdevelopmentapplicationsinjordanandsaudiarabia:
    'تطوير تطبيقات الويب في الأردن والمملكة العربية السعودية',

  mobiledevelopmentapplicationsinjordan:
    'تطوير تطبيقات  الهواتف المحمولة في الأردن ',
  mobiledevelopmentapplicationsinsaudiarabia:
    'تطوير تطبيقات  الهواتف المحمولة المملكة العربية السعودية',
  mobiledevelopmentappinjordan: 'تطوير تطبيقات  الهواتف المحمولة في الأردن',
  mobiledevelopmentcompanyinjordan:
    'شركة تطوير تطبيقات  الهواتف المحمولة في الأردن',

  digisolLogo: 'شركة ديجيسول لبرمجة تطبيقات الهاتف في الاردن',
  digibot: {
    navbar: 'البوت',
    customerInteraction: {
      alt: 'التفاعل مع العملاء',
      heading: {
        main: 'مرحبًا بك في مستقبل',
        emphasis: 'التفاعل مع العملاء',
      },
      description:
        'تمكن خدمة الدردشة الآلية لدينا عملك من الرد بسرعة على الاستفسارات عبر الإنترنت، محولة الفرص إلى عملاء راضين - لا مزيد من التأخير، لا مزيد من مشاكل التوظيف.',
    },
    customerEngagement: {
      alt: 'جذب العملاء',
      heading: {
        main: 'قم بثورة في جذب عملائك  بواسطة',
        emphasis: 'الروبوتات الذكية',
      },
    },
    benefits: {
      alt: 'فوائد استخدام الروبوتات الذكية',
      heading: {
        main: 'لماذا تختار ',
        emphasis: 'الروبوتات الذكية؟',
      },
      items: [
        'استجابة فورية',
        'كفاءة تكلفة',
        'توفر على مدار 24/7',
        'توحيد العلامة التجارية',
        'تحليل البيانات',
        'تصنيف الزعماء',
        'دعم متعدد القنوات',
        'زيادة الإنتاجية',
      ],
    },
    process: {
      heading: {
        main: 'كيف يعمل؟',
        emphasis: '',
      },
      steps: [
        {
          index: '01',
          title: 'تواصل معنا لتخصيص روبوت  الدردشة الخاص بك',
          description:
            'نقوم بتصميم الروبوت ليلائم صوت علامتك التجارية واحتياجات عملك بشكل محدد.',
        },
        {
          index: '02',
          title: 'تكامل متعدد القنوات',
          description:
            'ندمج روبوت الدردشة بشكل سلس مع مواقع الويب الخاصة بك وتطبيق الجوال وواتساب ومنصات وسائل التواصل الاجتماعي.',
        },
        {
          index: '03',
          title: 'تلقائية ذكية',
          description:
            'تم الانتهاء! دع الروبوت يتعامل مع الاستفسارات الروتينية واترك فريقك يركز على المهام المعقدة.',
        },
      ],
    },
    targeted_market: {
      heading: {
        pre: 'من يستفيد من',
        emphasis: 'هذه الخدمة؟',
        post: '',
      },
      subtitle: {
        main: 'جميع أحجام الأعمال',
      },
      items: {
        marketing: {
          title: 'وكالات التسويق',
          description:
            'تقديم دعم على مدار 24/7 لاستفسارات عملائك، وتبسيط تواصل العملاء وتقليل تكاليف مدير المجتمع.',
        },
        e_commerce: {
          title: 'الشركات التجارية الإلكترونية',
          description:
            'تحسين دعم العملاء، وزيادة معدلات التحويل، وتعزيز تجربة المستخدم من خلال الردود التلقائية على استفسارات المنتج.',
        },
        retail: {
          title: 'تجار التجزئة عبر الإنترنت',
          description:
            'تحسين التفاعل مع العملاء، وتقليل ترك العربة، وتتبع الطلبات بشكل فعّال من خلال الردود التلقائية.',
        },
        tech: {
          title: 'شركات التكنولوجيا',
          description:
            'التكامل مع المنصات الحالية، مما يوفر للشركات الراغبة في التكنولوجيا حلاً حديثًا لدعم العملاء.',
        },
        education: {
          title: 'المؤسسات التعليمية',
          description:
            'الردود التلقائية على استفسارات القبول، والاستفسارات المتعلقة بالدورات، وتوفير المعلومات الفورية للطلاب وأولياء الأمور.',
        },
        hospitality: {
          title: 'صناعة الضيافة',
          description:
            'تسهيل استفسارات الحجز، وتوفير معلومات حول المرافق، وتعزيز تجربة الضيوف بشكل عام.',
        },
        sme: {
          title: 'الشركات الصغيرة والمتوسطة (المشروعات الصغيرة والمتوسطة',
          description:
            'حلاً ميسرًا للاستفسارات الخاصة بالعملاء، مما يوفر الوقت والموارد ويضمن الرد السريع على الاستفسارات.',
        },
        startups: {
          title: 'الشركات الناشئة',
          description:
            'حلاً فعّالاً للتعامل مع تفاعلات العملاء، مما يتيح للشركات الناشئة التركيز على وظائف الأعمال الأساسية.',
        },
        services: {
          title: 'شركات الخدمات',
          description:
            'التعامل الفعّال مع استفسارات الخدمة، وجدولة المواعيد، والتفاعل مع العملاء دون الحاجة إلى مراقبة يدوية مستمرة.',
        },
        healthcare: {
          title: 'مقدمي الخدمات الصحية',
          description:
            'التعامل مع جدولة المواعيد، والاستفسارات العامة، وتوفير المعلومات الصحية في الوقت الفعلي، مما يعزز رضا المرضى.',
        },
        finance: {
          title: 'خدمات الخدمات المالية',
          description:
            'التعامل مع استفسارات الأمور المالية الروتينية، ومساعدة في استفسارات ذات صلة بالحسابات، وتوفير دعم فوري للمهام المصرفية الشائعة.',
        },
        real_estate: {
          title: 'وكالات العقارات',
          description:
            'التعامل الفعّال مع استفسارات العقارات، وجدولة عروض الممتلكات، وتوفير ردود فورية على استفسارات العملاء.',
        },
      },
    },
    get_started: {
      heading: {
        main: 'هل أنتم مستعدون لثورة تفاعلكم مع العملاء؟',
        emphasis: 'اكتشفوا مستقبل الاتصالات مع خدمة الدردشة الآلية لدينا',
      },
      cta: {
        text: 'ابدأ الآن!',
      },
    },
    contact: {
      heading: {
        main: 'تواصل معنا',
      },
      form: {
        email: {
          placeholder: 'البريد الإلكتروني',
        },
        message: {
          placeholder: 'رسالتك',
        },
        submitButton: {
          text: 'إرسال',
        },
      },
    },
    slider: {
      alt: 'شخصية بوت',
    },
    hero: {
      heading: {
        main: 'أطلق قوة الذكاء الاصطناعي',
        emphasis: 'روبوتات الدردشة بالذكاء الاصطناعي لتلبية جميع احتياجاتك',
      },
      description:
        'قم بتعزيز خدمة العملاء لديك باستخدام الروبوتات الذكية القادرة على التعامل مع الاستفسارات على موقع الويب الخاص بك، وعلى تطبيق الواتساب، وعلى وسائل التواصل الاجتماعي، مما يوفر ردودًا فورية على مدار 24/7.',
    },
  },
}
