import { memo } from 'react'
import logo from '../../../Images/digibot.svg'
import s from './style.module.css'
import { useTranslation } from 'react-i18next'

const DigibotHero = () => {
  const { t } = useTranslation()
  const tn = t('digibot', { returnObjects: true }).hero

  return (
    <section className={s.section}>
      <h1 className={`${s.text} ${s.heading}`}>{tn.heading.main}</h1>
      <h1 className={`${s.text} ${s.heading} ${s.emphasis}`}>
        {tn.heading.emphsis}
      </h1>

      <p className={`${s.text} ${s.description} `}>{tn.description}</p>
      <img src={logo} alt="logo" className={s.logo} />

      <div className={s.gradientOverlay} />
    </section>
  )
}

export default memo(DigibotHero)
