import React, { useEffect, useState } from "react";
import loadable from '@loadable/component'
import { Helmet } from "react-helmet";
import "./Home.css";
import styleHomeBlogs from "./NewFixes/BlogsHome.module.scss";
import { useTranslation } from "react-i18next";
import GoToTop from "../GoToTop.js";
import styleTetsimonilas from './NewFixes/Testimonials.module.scss';
import bgImage from "../Images/MobileApplication.webp";

const Testimonials = loadable(() => import("./NewFixes/Testimonials"));
const Technologies = loadable(() => import("./Technologies.js"));
const OurApplication  = loadable(() => import("./OurApplication.js"));
const BlogsHome  = loadable(() => import("./NewFixes/BlogsHome.js"));
const TalkToUs  = loadable(() => import("./NewFixes/TalkToUs"));
const WhatWeDo = loadable(() => import("./WhatWeDo.js"));


const Home = (props) => {
  const { t } = useTranslation();
  const [metaData, setMetaData] = useState([]);

  const getMetaData = async () => {
    const response = await fetch(props?.metaDataUrl);
    const data = await response.json();
    setMetaData(data);
  };

  useEffect(() => {
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <section id="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaData[1]?.title}</title>
          <meta name="keywords" content={metaData[1]?.keywords} />
          <meta name="description" content={metaData[1]?.description} />
        </Helmet>

        <img src={bgImage} alt={t("seoAltHome")} className="bgImageHome" loading="lazy"/>
        <WhatWeDo ourServicesHome={props.ourServicesHome} />

        <OurApplication />

        <div className={styleTetsimonilas.testimonialsSectionHeader}>
          <h4>{t("Findoutwhatourmostsuccessfulclientssayaboutus")}</h4>
          <p>
            {t(
              "Itsalwaysthebestsourcetohearfromotherswhohavealreadyworkedwithus"
            )}
          </p>
        </div>
        <Testimonials testimonailas={props.testimonailas} />

        <div className={styleHomeBlogs.latestBlogsSection}>
          <div className={styleHomeBlogs.homeBlogsSectionHeader}>
            <h5>{t("RECENTHIGHLIGHTS")}</h5>
            <h4>{t("Learnmoreaboutmarktinginourfeaturedblogentries")}</h4>
          </div>
          <BlogsHome api={props.url2} />
        </div>

        <Technologies />

        <TalkToUs />

        <GoToTop />
        <h1 className="headingWin">
          {t("mobiledevelopmentapplicationsinjordanandsaudiarabia")}
        </h1>
        <h1 className="headingWin">
          {t("restaurantdevelopmentapplicationsinjordanandsaudiarabia")}
        </h1>
        <h1 className="headingWin">
          {t("restaurantappsapplicationsinjordanandsaudiarabia")}
        </h1>
        <h1 className="headingWin">
          {t("deliveryappsapplicationsinjordanandsaudiarabia")}
        </h1>
        <h1 className="headingWin">
          {t("webdevelopmentapplicationsinjordanandsaudiarabia")}
        </h1>
      </section>
    </>
  );
};

export default React.memo(Home);
