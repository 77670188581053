import { useTranslation } from 'react-i18next'
import customerEngagement from '../../../Images/digibot-customer-engagement.svg'
import s from './style.module.css'

export default function CustomerEngagement() {
  const { t } = useTranslation()
  const tn = t('digibot', { returnObjects: true }).customerEngagement

  return (
    <section className={s.section}>
      <h1 className={`${s.heading} ${s.text}`}>
        {tn.heading.main}{' '}
        <span className={s.emphasis}>{tn.heading.emphasis}</span>
      </h1>
      <img src={customerEngagement} alt={tn.alt} className={s.hero} />
    </section>
  )
}
