/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./ContactUsPage.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const ContactUsPage = (props) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const dir = {
    direction: `${i18n.dir()}`,
  };

  const [phoneCall, setPhoneCall] = useState("");
  const [emailCall, setEmailCall] = useState("");
  const [whatsappCall, setWhatsappCall] = useState("");
  const [metaData, setMetaData] = useState([]);

  const getMetaData = async () => {
    const response = await fetch(props.metaDataUrl);
    const data = await response.json();
    setMetaData(data);
  };

  useEffect(() => {
    getMetaData();
  }, [props]);
  axios.get(props.url).then((data) => {
    setWhatsappCall(data.data.data[0].whatsapp);
    setPhoneCall(data.data.data[0].phone);
    setEmailCall(data.data.data[0].email);
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, SetDescription] = useState("");
  const [messageAfter, setMessageAfter] = useState("");
  const [optOne, setOptOne] = useState("");
  const [optTwo, setOptTwo] = useState("");
  const [optThree, setOptThree] = useState("");
  const [optFour, setOptFour] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();

    const requea = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        description: description,
        notes: optOne || optTwo || optThree || optFour,
      }),
    };
    try {
      let res = await fetch(
        "https://dashboard.company.digisolapps.com/api/en/digisol/ContactUs",
        requea
      );
      await res.json();
      if (res.status < 300 || res.status >= 200) {
        setName("");
        setEmail("");
        setPhone("");
        SetDescription("");
        setOptOne("");
        setOptTwo("");
        setOptThree("");
        setOptFour("");

        setMessageAfter("Message sent");
        window.location.reload(false);
      } else {
        setMessageAfter("Some error occured");
      }
    } catch (err) {
     }
  };
  return (
    <div
      id="contactUsPage-parent"
      className={`${dir.direction === "ltr" ? "leftToRigh" : "rightToLeft"}`}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaData[7]?.title?metaData[7].title:'Digi Sol Fze - mobile application development in jordan'}</title>
        <meta name="keywords" content={metaData[7]?.keywords} />
        <meta name="description" content={metaData[7]?.description} />
      </Helmet>
      <div className="container">
        <div className="content">
          <div className="left-side">
            <div className="address details">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Digisolfze/@31.9756842,35.862501,18.96z/data=!4m5!3m4!1s0x151ca1739960f2e9:0x5e2873ecaa2cb00!8m2!3d31.9755749!4d35.8619016"
              >
                {" "}
                <i className="fas fa-map-marker-alt"></i>
                <div className="topic">{t("address")}</div>
                <div className="text-one">{t("street")}</div>
                <div className="text-two">{t("amman")}</div>
              </a>
            </div>
            <a href={`tel:${phoneCall}`}>
              <div className="phone details">
                <i className="fas fa-phone-alt"></i>
                <div className="topic">{t("phone")}</div>
                <div className="text-one">{phoneCall}</div>
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${emailCall}?subject=Inquiry&body= Message`}
            >
              <div className="email details">
                <i className="fas fa-envelope"></i>
                <div className="topic">{t("email")}</div>
                <div className="text-one">{emailCall}</div>
              </div>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://wa.me/${whatsappCall}?text=Hello`}
            >
              <div className="email details">
                <i className="fa-brands fa-whatsapp"></i>{" "}
                <div className="topic">{t("whatsApp")}</div>
                <div className="text-one">{whatsappCall}</div>
              </div>
            </a>
          </div>
          <div className="right-side">
            <div className="topic-text">{t("Sendusamessage")}</div>
            <p>{t("query")}</p>

            <form onSubmit={handleSubmit}>
              <div className="input-box">
                <input
                  type="text"
                  placeholder={t("name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="input-box">
                <input
                  type="email"
                  value={email}
                  placeholder={t("email")}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="input-box">
                <input
                  type="text"
                  value={phone}
                  placeholder="[+962][000 000 000]"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <span className="text-muted">{t("yourinquiryabout")}</span>
              <div id="iqury-box">
                <input
                  type="radio"
                  value="I Like to discuss your offer"
                  onChange={(e) => setOptOne(e.target.value)}
                  name="opt"
                />{" "}
                {t("ILiketodiscussyouroffer")}
                <input
                  type="radio"
                  value="Call Me Back"
                  onChange={(e) => setOptTwo(e.target.value)}
                  name="opt"
                />{" "}
                {t("callMeBack")}
                <input
                  type="radio"
                  value="I Need A Quotation For My Inquiry"
                  onChange={(e) => setOptThree(e.target.value)}
                  name="opt"
                />
                {t("qInq")}
                <input
                  type="radio"
                  value="Others"
                  onChange={(e) => setOptFour(e.target.value)}
                  name="opt"
                />{" "}
                {t("Others")}
              </div>

              <div className="input-box message-box">
                <input
                  type="text"
                  placeholder="Enter your Message"
                  value={description}
                  onChange={(e) => SetDescription(e.target.value)}
                  required
                />
              </div>
              <div className="button">
                <button id="buttonSender" type="submit">
                  {t("submit")}
                </button>
                <div className="message">
                  {messageAfter ? <p>{messageAfter}</p> : null}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContactUsPage);
