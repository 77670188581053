import { useTranslation } from 'react-i18next'
import customerInteraction from '../../../Images/digibot-customer-interaction.svg'
import s from './style.module.css'

export default function CustomerInteraction() {
  const { t } = useTranslation()
  const tn = t('digibot', { returnObjects: true }).customerInteraction

  return (
    <section className={s.section}>
      <img src={customerInteraction} alt={tn.alt} className={s.hero} />
      {
        // TODO the little star to the end
      }
      <h1 className={`${s.heading} ${s.text}`}>
        {tn.heading.main}{' '}
        <span className={s.emphasis}>{tn.heading.emphasis}</span>
      </h1>

      <p className={`${s.description} ${s.text}`}>{tn.description}</p>
    </section>
  )
}
