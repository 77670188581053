import React, { useState, useEffect } from "react";
import "./NewNavbar.css";
import logo from "../Images/DLogo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewNavbar({ onLanguageChange, dlanguage }) {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(dlanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language, i18n]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  return (
    <nav
      className="navbar"
      style={language === "ar" ? { direction: "rtl" } : {}}
    >
      <ul
        className={isOpen ? "navbar-links active" : "navbar-links"}
        style={language === "ar" ? { marginRight: "10%" } : {}}
      >
        <li>
          <img
            src={logo}
            alt={t('digisolLogo')}
            onClick={() => navigate("/")}
            loading="lazy"
          />
        </li>
        <li>
          <NavLink to="/" onClick={() => setIsOpen(!isOpen)}>
            {t("home")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/mobile-development-applications-in-jordan-and-saudi-arabia"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("about")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/websites-development-applications-in-jordan-and-saudi-arabia-middle-east"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("ourServices")}
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/digibot"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("digibot", { returnObjects: true }).navbar }
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/applications-ios-android-huawei-apps-app-store-jordan"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("ourProjects")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blogs-webBlogs-phoneBlogs-appBlogs-articles-saudi-arabia-jordan"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("blogs")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/invest-in-apps-mobile-applications-online-investments"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("invest")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/javascript-react-js-python-IT-jobs-in-jordan-php-laravel"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("careers")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact-IT-company-Jordan-mobile-applications-development"
            onClick={() => setIsOpen(!isOpen)}
          >
            {t("contact")}
          </NavLink>
        </li>

        <div className="languageSelectorContainer">
          <h6
            className="languageSelector"
            onClick={() => {
              setLanguage("ar");
            }}
          >
            ع
          </h6>
          <h6 className="languageSelector" onClick={() => setLanguage("en")}>
            En
          </h6>
        </div>
      </ul>
      <div className="navbar-toggle" onClick={toggleNavbar}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
}

export default React.memo(NewNavbar);
