import { useTranslation } from 'react-i18next'
import s from './style.module.css'

export default function GetStarted() {
  const { t } = useTranslation()
  const tn = t('digibot', { returnObjects: true }).get_started

  return (
    <section className={s.section}>
      <h1 className={`${s.heading} ${s.text}`}>{tn.heading.main}</h1>
      <h1 className={`${s.heading} ${s.emphasis} ${s.text}`}>{tn.heading.emphasis}</h1>

      <button className={`${s.text} ${s.cta}`}>
        {tn.cta.text}
        <div className={s.ctaRadialGradient} />
      </button>

      <div className={s.radialGradientOverlay} />
    </section>
  )
}
