import { Swiper, SwiperSlide } from 'swiper/react'
import bot1 from '../../../Images/bot-1.png'
import bot2 from '../../../Images/bot-2.png'
import bot3 from '../../../Images/bot-3.png'
import bot4 from '../../../Images/bot-4.png'
import bot5 from '../../../Images/bot-5.png'
import 'swiper/css'
import SwiperCore, { Autoplay } from 'swiper'
import s from './style.module.css'
import { useTranslation } from 'react-i18next'

SwiperCore.use([Autoplay])

export default function Slider() {
  const { t } = useTranslation()
  const tn = t('digibot', { returnObjects: true }).slider

  const bots = [bot1, bot2, bot3, bot4, bot5]

  return (
    <section className={s.section}>
      <Swiper
        slidesPerView={3}
        modules={[Autoplay]}
        speed={1500}
        loop
        autoplay={{
          delay: 80,
          disableOnInteraction: false,
        }}
        spaceBetween={180}
        breakpoints={{
          500: {
            spaceBetween: 170, 
          },
          600: {
            spaceBetween: 140, 
          },
          725: {
            spaceBetween: 100, 
          },
        }}
        centeredSlides
        allowTouchMove={false}
      >
        {bots.map((b, index) => (
          <SwiperSlide key={index}>
            <img src={b} alt={tn.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
