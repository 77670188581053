export const TRANSLATIONS_EN = {
  home: 'Home',
  about: 'About Us',
  ourproducts: 'Our products',
  contact: 'Contact Us',
  careers: 'Careers',
  whoweare: 'Who We Are',
  our_products: 'Our Products',
  our_customers: 'Our Clients',
  testimonials: 'Testimonials',
  news: 'News',
  wanttoknowmorw: 'Want To Know More ?',
  submit: 'Send Message',
  name: 'Name',
  email: 'Email',
  subject: 'Subject',
  message: 'Message',
  weeklyBlogs: 'Weekly Blogs',
  seeall: 'See All',
  readmore: 'Read more',
  vision: 'Our Vision',
  mission: 'Our Mission',
  values: ' Our Values',
  mailus: 'Mail us',
  findus: 'Find us',
  ourServices: 'Our Services',
  ourProjects: 'Projects',
  browse: 'BROWSE OUR PROJECTS',
  knowMore: 'Know More',
  mobileAppDevelopment: 'Mobile App Development',
  webAppDevelopment: 'Web App Development',
  digitalMarkting: 'digitalMarkting',
  whatWeDo: 'What we do',
  discoverOurApplications: 'Discover Our Applications',
  ourApps: 'OUR APPS',
  getInTouch: 'GETTING IN TOUCH IS EASY!',
  getInTouchFooter: 'GET IN TOUCH!',
  getDescription:
    'Its Very Easy To Get In Touch With Us. Just Use The Contact Form Or Pay Us A Visit For A Coffee At The Office.',
  address: 'Address',
  street: 'Makkah Street',
  amman: 'Amman',
  phone: 'Phone',
  Sendusamessage: 'Send us a message',
  query:
    'if you have any types of quries, you can send us a message from here. Its our pleasure to help you.',

  blogs: 'Blogs',
  blogsLatest: 'Our latest blogs',

  ourProcess: 'Our Process',
  OurAppsCategories: 'Our App Categories',
  DigitalMarketingServicesWeOffer: 'Digital Marketing Services We Offer',
  SearchEngineOptimizationService: 'Search Engine Optimization Service',
  seoParagraph:
    'Search engine optimization or SEO is an integral cog in creating a company that has an online presence. Almost all of your customers are online and you need to ensure that they are able to find you. We here at Digisol offer just that. We have an amazing SEO team that is made up of SEO experts with years of experience. Our versatile team will sit down with you and work out a special customized SEO plan specifically suited for your business. With Digisol SEO solutions you will get:',
  pOne: 'Dedicated SEO Manager ',
  pTwo: 'In-Depth Market & Competitor Analysis',
  pThree: 'Technical Site Audit',
  pFour: 'Website Optimization',
  pFive: 'Landing Page Re-Design',
  pSix: 'Content Creation',
  pSeven: 'Advertisement Management',
  pEight: 'Transparent Reporting With Easy To Understand Metrics',
  whatsApp: 'Whats App',
  yourinquiryabout: 'your inquiry about',
  ILiketodiscussyouroffer: 'I Like to discuss your offer',
  callMeBack: 'Call me back',
  qInq: ' I Need A Quotation For My Inquiry',
  Others: 'Others',
  userFr: 'USERFRIENDLY  DESIGN ',
  projects: 'Our Applications',
  sync: 'Amazing Applications to Synchronize Your Phone & Your life. Be mobile . Be popular . Be trendy',
  Testimonials: 'Testimonials',
  ourClients: 'OUR CLIENTS',
  lang: 'Language',
  store: 'Sitemap',
  apply: 'Apply Now',
  services: 'Our Services',
  clients: 'Clients',
  team: 'Our Team',
  tech: 'Technologies',
  invest: 'Invest',
  formoreinformation: 'For more information',
  send: 'Send',
  selectproject: 'Select Project',
  thankyouWewillcontactyousoon: 'Thank you , We will contact you soon',
  Findoutwhatourmostsuccessfulclientssayaboutus:
    'Find out what our most successful clients say Our mobile applications',
  Itsalwaysthebestsourcetohearfromotherswhohavealreadyworkedwithus:
    "It's always the best source to hear from others who have already worked with us",
  RECENTHIGHLIGHTS: 'RECENT HIGHLIGHTS',
  Learnmoreaboutmarktinginourfeaturedblogentries:
    'Learn more about Markting in Our featured blog entries',
  Deliveramazingdigitalexperiences: 'Deliver amazing digital experiences.',
  Wevehelpedthousandsofcompaniesjustlikeyourschangetheirbusinesses:
    'We have helped thousands of companies, just like yours, change their businesses.',
  ApplicationSent: 'Thank You ✨ Your Application Has Been Sent',
  vacancies: 'Vacancies',
  seoAltHome: 'mobile development applications in jordan',
  mobiledevelopmentapplicationsinjordanandsaudiarabia:
    'mobile development applications in jordan and saudi arabia',
  restaurantdevelopmentapplicationsinjordanandsaudiarabia:
    'restaurant development applications in jordan and saudi arabia',
  restaurantappsapplicationsinjordanandsaudiarabia:
    'restaurant apps applications in jordan and saudi arabia',
  deliveryappsapplicationsinjordanandsaudiarabia:
    'delivery apps applications in jordan and saudi arabia',
  webdevelopmentapplicationsinjordanandsaudiarabia:
    'web development applications in jordan and saudi arabia',
  mobiledevelopmentapplicationsinjordan:
    'mobile development applications in jordan',
  mobiledevelopmentapplicationsinsaudiarabia:
    'mobile development applications in saudi arabia',
  mobiledevelopmentappinjordan: 'mobile development applications in jordan',
  mobiledevelopmentcompanyinjordan: 'mobile development applications in jordan',
  digisolLogo: 'Digisol company for mobile applications development in Jordan',
  digibot: {
    navbar: "Digibot", 
    customerInteraction: {
      alt: 'customer interaction',
      heading: {
        main: 'Welcome to the future of',
        emphasis: 'Customer Interaction',
      },
      description:
        'Our AI Chatbot Service empowers your business to respond swiftly to online inquiries, turning leads into satisfied customers—no more  delays, no more hiring hassles.',
    },
    customerEngagement: {
      alt: 'customer engagement',
      heading: {
        main: 'Revolutionize Your Customer Engagement with',
        emphasis: 'AI Chatbots',
      },
    },
    benefits: {
      alt: 'benefits of using AI',
      heading: {
        main: 'Why Choose',
        emphasis: 'AI Chatbots?',
      },
      items: [
        'Instant Responsiveness',
        'Cost-Efficiency',
        '24/7 Availability',
        'Consistent Branding',
        'Data Analysis',
        'Lead Qualification',
        'Multichannel Support',
        'Increase Productivity',
      ],
    },
    process: {
      heading: {
        main: 'It Works',
        emphasis: 'How',
      },
      steps: [
        {
          index: '01',
          title: 'Contact us to customize  your Chatbot',
          description:
            'We tailor chatbots to match your brand voice and specific business needs.',
        },
        {
          index: '02',
          title: 'Multi-Platform Integration',
          description:
            'We seamlessly integrate your chatbot with your websites, mobile application, WhatsApp, and social media platforms.',
        },
        {
          index: '03',
          title: 'Smart Automation',
          description:
            'Done! Let your Bot handle the routine inquiries and leave your team to focus on complex tasks.',
        },
      ],
    },
    targeted_market: {
      heading: {
        pre: 'Who',
        emphasis: 'benefits',
        post: 'from this service?',
      },
      subtitle: {
        main: 'Businesses of all Sizes!',
      },
      items: {
        marketing: {
          title: 'Marketing Agencies',
          description:
            'Provide 27/7 support to your customers’ inquiries, streamline client communication and reduce community manager costs.',
        },
        e_commerce: {
          title: 'E-commerce Businesses',
          description:
            'Streamlined customer support, increased conversion rates, and enhanced user experience through instant responses to product-related queries.',
        },
        retail: {
          title: 'Online Retailers',
          description:
            'Improved customer engagement, reduced cart abandonment, and efficient order tracking through automated responses.',
        },
        tech: {
          title: 'Technology Companies',
          description:
            'Integration with existing platforms, providing tech-savvy businesses with a cutting-edge customer support solution.',
        },
        education: {
          title: 'Educational Institutions',
          description:
            'Automating responses to admission queries, course-related inquiries, and providing instant information to students and parents.',
        },
        hospitality: {
          title: 'Hospitality Industry',
          description:
            'Streamlining booking inquiries, providing information on amenities, and enhancing the overall guest experience.',
        },
        sme: {
          title: 'Small and Medium-sized Enterprises (SMEs)',
          description:
            'Affordable automation for customer queries, saving time and resources, and ensuring a prompt response to inquiries.',
        },
        startups: {
          title: 'Startups',
          description:
            'Cost-effective solution for handling customer interactions, allowing startups to focus on core business functions.',
        },
        services: {
          title: 'Service-Based Businesses',
          description:
            'Efficient handling of service inquiries, appointment scheduling, and client interactions without the need for constant manual monitoring.',
        },
        healthcare: {
          title: 'Healthcare Providers',
          description:
            'Handling appointment scheduling, general inquiries, and providing health-related information in real-time, enhancing patient satisfaction.',
        },
        finance: {
          title: 'Financial Services',
          description:
            'Handling routine financial queries, assisting with account-related inquiries, and providing instant support for common banking tasks.',
        },
        real_estate: {
          title: 'Real Estate Agencies',
          description:
            'Efficiently handling property inquiries, scheduling property viewings, and providing instant responses to client queries.',
        },
      },
    },
    get_started: {
      heading: {
        main: 'Ready to revolutionize your customer interactions?',
        emphasis:
          'Explore the future of communication with our AI Chatbot Service.',
      },
      cta: {
        text: 'Get Started',
      },
    },
    contact: {
      heading: {
        main: 'Contact Us',
      },
      form: {
        email: {
          placeholder: 'Your email',
        },
        message: {
          placeholder: 'Your message',
        },
        submitButton: {
          text: 'Enter',
        },
      },
    },
    slider: {
      alt: 'bot avatar',
    },
    hero: {
      heading: {
        main: 'Unleash the Power of AI', 
        emphasis: 'AI Chatbot for all your needs',
      },
      description: 'Enhance your customer service with intelligent chatbots capable of handling inquiries on your website, WhatsApp, and social media, providing instant responses 24/7.',

    }
  },
}
