import React, { useState,  useEffect } from "react";
import "./Footer.css";
import { NavLink, useNavigate } from "react-router-dom";
// import whatApp from "../Images/whatsapp.png";
import twitter from "../Images/twitter.png";
import facebook from "../Images/facebook.png";
import instagram from "../Images/instagram.png";
import { useTranslation } from "react-i18next";
import linkedIn from "../Images/linkedin.png";

const Footer = ({ url, onLanguageChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const { i18n } = useTranslation();
  const dir = {
    direction: `${i18n.dir()}`,
  };
  const [teamTitle, setTeamTitle] = useState("");
  const [teamDescription, setTeamDescription] = useState("");

  const [aboutDigisolTitle, setAboutDigisolTitle] = useState("");
  const [aboutDigisolDescription, setAboutDigisolDescription] = useState("");

  const [whyDigisolTitle, setWhyDigisolTitle] = useState("");
  const [whyDigisolDescription, setWhyDigisolDescription] = useState("");

  const getFooterData = async () => {
    const response = await fetch(url);
    const { data } = await response.json();
    setTeamTitle(data?.footer[0]?.title);
    setTeamDescription(data?.footer[0]?.description);

    setAboutDigisolTitle(data?.footer[1]?.title);
    setAboutDigisolDescription(data?.footer[1]?.description);

    setWhyDigisolTitle(data?.footer[2]?.title);
    setWhyDigisolDescription(data?.footer[2]?.description);
  };

  useEffect(() => {
    getFooterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <>
      <footer
        className={`${dir.direction === "ltr" ? "leftToRigh" : "rightToLeft"}`}
      >
        <div className="main">
          <div className="col1">
            <h3 className="heading">{t("store")}</h3>
            <ul className="ulFooter">
              <li>
                <NavLink to="/">{t("home")}</NavLink>
              </li>
              <li>
                <NavLink to="/mobile-development-applications-in-jordan-and-saudi-arabia">{t("about")}</NavLink>
              </li>
              <li>
                <NavLink to="/websites-development-applications-in-jordan-and-saudi-arabia-middle-east">{t("ourServices")}</NavLink>
              </li>
              <li>
                <NavLink to="/applications-ios-android-huawei-apps-app-store-jordan">{t("ourProjects")}</NavLink>
              </li>

              <li>
                <NavLink to="/contact-IT-company-Jordan-mobile-applications-development">{t("contact")}</NavLink>
              </li>
            </ul>
          </div>

          <div className="col2" style={{display:'flex', flexDirection:'column', gap:'10%'}}>
          <a
                  href={"https://twitter.com/digisolfze"}
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="digisol whatsapp"
                >
                  <img
                    src={twitter}
                    alt="digisol twitter"
                    width={100}
                    height={30}
                  />
                </a>
                <a
                  href={"https://www.facebook.com/DigisolME/"}
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="digisol whatsapp"
                >
                  <img
                    src={facebook}
                    alt="digisol facebook"
                    width={100}
                    height={30}
                  />
                </a>
                
                <a
                  href={
                    "https://www.instagram.com/digisolme/?igshid=NTdlMDg3MTY%3D"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="digisol whatsapp"
                >
                  <img
                    src={instagram}
                    alt="digisol instagram"
                    width={100}
                    height={30}
                  />
                </a>

                <a
                  href={"https://www.linkedin.com/company/digisolfze/"}
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="digisol whatsapp"
                >
                  <img
                    src={linkedIn}
                    alt="digisol linkedin"
                    width={100}
                    height={30}
                  />
                </a>
          </div>


          <div className="col2">
            <h3 className="heading" onClick={()=>navigate('/mobile-development-applications-in-jordan-and-saudi-arabia')}>{aboutDigisolTitle}</h3>
            <div className="OurtemsFooter" align="justify" onClick={()=>navigate('/mobile-development-applications-in-jordan-and-saudi-arabia')}>
              {aboutDigisolDescription}
            </div>
          </div>

          <div className="col2">
            <h3 className="heading" onClick={()=>navigate('/mobile-development-applications-in-jordan-and-saudi-arabia')}>{whyDigisolTitle}</h3>
            <div className="OurtemsFooter" align="justify" onClick={()=>navigate('/mobile-development-applications-in-jordan-and-saudi-arabia')}>
              {whyDigisolDescription}
            </div>
          </div>

          <div className="col2">
            <h3 className="heading" onClick={()=>navigate('/mobile-development-applications-in-jordan-and-saudi-arabia')}>{teamTitle}</h3>
            <div className="OurtemsFooter" align="justify" onClick={()=>navigate('/mobile-development-applications-in-jordan-and-saudi-arabia')}>
              {teamDescription}
            </div>

            <div className="col3">
              <br />
              <br />
               <div className="social">
               
               

              </div>
            </div>
          </div>
        </div>
        <p className="terms">
          <span>Powered by &#169; Digisol</span>
        </p>
      </footer>
 
    </>
  );
};

export default React.memo(Footer);
