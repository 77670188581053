import { useIsScrollingDown } from '../../lib/hooks'
import styles from './style.module.css'
import { FaWhatsapp } from "react-icons/fa";

export default function WhatsappFab() {
  const { isScrollingDown } = useIsScrollingDown()
  console.log(isScrollingDown)

  return (
    <div className={`${styles.container} ${isScrollingDown ? styles.visible : styles.hidden}`}>
      <a href="https://bit.ly/45Ayw8O" target="_blank" rel="noreferrer">
        <FaWhatsapp className={styles.icon} size={'36px'} color='white'/>
      </a>
    </div>
  )
}
