import { memo, useEffect } from 'react'
import DigibotHero from './components/hero'
import s from './style.module.css'
import CustomerInteraction from './components/CustomerInteraction'
import CustomerEngagement from './components/CustomerEngagement'
import Benefits from './components/Benefits'
import Process from './components/Process'
import TargetedMarket from './components/TargetedMarket'
import GetStarted from './components/GetStarted'
import ContactForm from './components/ContactForm'
import Slider from './components/slider'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import WhatsappFab from '../whatsapp-fab'

const Digibot = () => {
  const { i18n } = useTranslation() 
  const isArabic = i18n.resolvedLanguage === 'ar' ?? false

  const [ params ] = useSearchParams()

  useEffect(() => {
    const locale = params.get("locale")
    if (locale) {
      i18n.changeLanguage(locale)
    }
  }, [i18n, params])

  return (
    <main className={s.main} {...(isArabic ? {'data-arabic': true} : {})}>
      <DigibotHero />
      <CustomerInteraction />
      <CustomerEngagement />
      <Benefits />
      <Process />
      <TargetedMarket />
      <GetStarted />
      <Slider />
      <ContactForm />
      <WhatsappFab />
    </main>
  )
}

export default memo(Digibot)
