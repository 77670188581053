import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';

import { TRANSLATIONS_EN } from "./translations/en/en";
import { TRANSLATIONS_AR } from "./translations/ar/ar";

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      ar: {
        translation: TRANSLATIONS_AR
      },
      interpolation: {
        escapeValue: false,
      },
    },
  });

export default i18n
