import s from '../style.module.css'

export default function ProcessCard({ item }) {
  return (
    <div className={s.card}>
      <div className={s.headingContainer}>
        <span className={s.cardIndex}>{item.index}</span>
        <div className={s.headingDivider} />
        <h1 className={`${s.text} ${s.cardTitle}`}>{item.title}</h1>
      </div>

      <p className={s.cardDescription}>{item.description}</p>
    </div>
  )
}
