import { useTranslation } from 'react-i18next'
import s from './style.module.css'

import marketing from '../../../Images/marketing.svg'
import eCommerce from '../../../Images/e-commerce.svg'
import retail from '../../../Images/retail.svg'
import tech from '../../../Images/tech.svg'
import education from '../../../Images/education.svg'
import hospitality from '../../../Images/hospitality.svg'
import sme from '../../../Images/sme.svg'
import startup from '../../../Images/startup.svg'
import service from '../../../Images/service.svg'
import healthcare from '../../../Images/healthcare.svg'
import finance from '../../../Images/finance.svg'
import realEstate from '../../../Images/real-estate.svg'

export default function TargetedMarket() {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.resolvedLanguage === 'ar' ?? false
  const tn = t('digibot', { returnObjects: true }).targeted_market

  // maps translation items' keys to their icons
  const iconMappings = {
    marketing: marketing,
    e_commerce: eCommerce,
    retail: retail,
    tech: tech,
    education: education,
    hospitality: hospitality,
    sme: sme,
    startups: startup,
    services: service,
    healthcare: healthcare,
    finance: finance,
    real_estate: realEstate,
  }

  return (
    <section className={s.section} dir={isArabic && 'rtl'}>
      <h1 className={`${s.heading} ${s.text}`}>
        {tn.heading.pre}{' '}
        <span className={s.emphasis}>{tn.heading.emphasis}</span>{' '}
        {tn.heading.post}
      </h1>
      <h3 className={`${s.emphasis} ${s.text} ${s.subtitle}`}>
        {tn.subtitle.main}
      </h3>

      <div className={s.items}>
        {Object.entries(iconMappings).map((m, index) => {
          const [key, icon] = m
          const item = tn.items[key]

          return (
            <div className={s.item} key={index}>
              <img src={icon} width={32} alt={item.title} />
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}
