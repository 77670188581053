import React from 'react';
import './WebMail.css'
const WebMail = () => {
    window.location = "https://usm101.siteground.biz/webmail/mail/";


    return (
        <div>

            <div className="loadingTwo">Loading&#8230;</div>


        </div>
    )
}

export default WebMail